import React, { useState, useEffect, useRef } from "react";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
    Autocomplete
} from "@react-google-maps/api";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/styles';
import Geocode from "react-geocode";
// Geocode.setApiKey("AIzaSyBXLz2f0FmKJj-98ncABlISrs0WWxrymGo");
// Geocode.setApiKey("AIzaSyCDoPmvnBPd0XTaGSftzJC25Hva6BYxYz0");
Geocode.setApiKey("AIzaSyCs8J8gkndmunp7FLbMCMvr7bqONTxl1Lk");


const useStyles = makeStyles((theme) => ({
    mapsty: {
        height: "100%",
        width: "100%"
    }
}))

const MapTC = ({ width, array, isAdding, getLocation, longitude, latitude, icons, drag, handelmapchange, handalDomicilio }) => {
    const [selected, setSelected] = useState({});
    const [valuemap, setvaluemap] = useState("");
    const [currentPosition, setCurrentPosition] = useState({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    });

    const markerRef = useRef(null);

    const defaultCenter = {
        lat: 41.3851,
        lng: 2.1734
    };

    useEffect(() => {

    }, [currentPosition])

    useEffect(() => {
        setCurrentPosition({
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
        })


    }, [longitude, latitude])

    const classes = useStyles();

    const onSelect = (item) => {
        setSelected(item);
    };

    const success = (position) => {
        // const currentPosition = {
        //     lat: 21.2354,
        //     lng: 72.8588
        // };
        const currentPosition = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
        };
        setCurrentPosition(currentPosition);
    };

    const onMarkerDragEnd = (e) => {
        const lat = parseFloat(e.latLng.lat());
        // parseFloat(document.getElementById('lat').value)
        const lng = parseFloat(e.latLng.lng());
        setCurrentPosition({ lat, lng });
        handelmapchange(lat, lng)

        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                // console.log(address);
                handalDomicilio(address)
            },
            error => {
                console.error(error);
            }
        );
    };

    const footer = (
        <div className="footer">
            <div className="inner-footer">
                <span className="location-text">Choose location and press</span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getLocation(currentPosition)}
                >
                    Next
                </Button>
            </div>
        </div>
    );

    const mapStyles = () => {
        if (!isAdding) {
            return {
                // marginTop: "-20px",
                // height: "100%",
                // width: "100%",
                height: "500px",
                width: width ? width : "500px",
                borderRadius: '10px',
            }
        }
        // } else {
        //     return {
        //         // marginTop: "-20px",
        //         height: "100%",
        //         width: "100%"
        //     };
        // }
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    });

    const onPlaceSelected = (place) => {
        console.log('plc', place);
        setvaluemap(place)
        // const address = place.formatted_address,
        //       addressArray =  place.address_components,
        //       city = this.getCity( addressArray ),
        //       area = this.getArea( addressArray ),
        //       state = this.getState( addressArray ),
        //       latValue = place.geometry.location.lat(),
        //       lngValue = place.geometry.location.lng();
        // // Set these values in the state.
        // this.setState({
        // 	address: ( address ) ? address : '',
        // 	area: ( area ) ? area : '',
        // 	city: ( city ) ? city : '',
        // 	state: ( state ) ? state : '',
        // 	markerPosition: {
        // 		lat: latValue,
        // 		lng: lngValue
        // 	},
        // 	mapPosition: {
        // 		lat: latValue,
        // 		lng: lngValue
        // 	},
        // })
    };

    return (
        <GoogleMap
            // mapContainerStyle={mapStyles}
            mapContainerStyle={mapStyles()}
            // className={classes.mapsty}
            zoom={3}
            center={currentPosition}
        >
            {currentPosition.lat ? (
                <Marker
                    position={currentPosition}
                    onDragEnd={(e) => onMarkerDragEnd(e)}
                    draggable={drag}
                    icon={icons}
                    // title={"abc"}
                    // label="abc"
                    opacity={1}
                />
            ) : null}
            {/* <Autocomplete
                        style={{
                            width: '100%',
                            height: '40px',
                            paddingLeft: '16px',
                            marginTop: '2px',
                            marginBottom: '500px'
                        }}
                        onPlaceSelected={onPlaceSelected}
                        types={['(regions)']}
                    />  */}
        </GoogleMap>


    );
};

export default MapTC;


{/* <GoogleMap
    id="example-map"
    mapContainerStyle={mapStyles()}
    draggable={true}
    zoom={13}
    center={currentPosition.lat ? currentPosition : defaultCenter}
>
    {array
        ? array.map((item) => {
            return (
                <Marker
                    key={item.id}
                    position={item.location}
                    onClick={() => onSelect(item)}
                />
            );
        })
        : null}
    {isAdding ? (
        <Marker
            style={greatPlaceStyle}
            position={currentPosition}
            ref={() => markerRef}
            onDragEnd={(e) => onMarkerDragEnd(e)}
            draggable={true}
        />
    ) : null}
    {selected.location ? (
        <InfoWindow
            position={selected.location}
            onCloseClick={() => setSelected({})}
        >
            <div className="infowindow">
                <p>{selected.title}</p>
                <img
                    src={selected.image}
                    className="small-image"
                    alt="rental"
                />
                <p>price: {selected.price}</p>
                <p>sqm2: {selected.sqm}</p>
                <p>bedrooms: {selected.bedrooms}</p>
            </div>
        </InfoWindow>
    ) : null}
</GoogleMap> */}