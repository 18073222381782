import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

import { NavBar, TopBar } from './components';
import { useRef } from 'react';
import AppDataContext from 'Helper/AppDataContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& .makeStyles-root-31': {
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        padding: '16px'
      }
    }
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 300,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const Dashboard = props => {
  const { route } = props;
  const { appContext, setAppContext } = useContext(AppDataContext);
  const router = useRouter();

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [s, setS] = useState(0);
  const [sHeight, setSHeight] = useState(0);
  const [cHeight, setCHeight] = useState(0);
  const [tmp, setTmp] = useState(-1);
  const scrallRef = useRef();

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    // onScroll()
    let hash = window.location.hash
    if (hash === '#contactus') {
      setSHeight(4502)
    }
  }, [cHeight])
  console.log(sHeight, "sheigh");
  // let scr1 = document.getElementById('homeScrall');
  const homeScrallToFunLocal = (num) => {
    let scr = document.getElementById('homeScrall');
    console.log(scr.scrollTop, scr.scrollHeight, scr.clientHeight, "scr");
    console.log(num, "num");
    if (num && num != -1) {
      console.log(num, scr.scrollHeight, "num")
      // if (scr.scrollHeight != 6029) {
      //   setTmp(num)
      // } else {
      console.log(scr.scrollHeight, num, "ssss");
      // if (s != -1) {
      // setS(num)
      scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight - num);
      // } else {
      //   scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight - s);
      // }
      // }
      router.history.push("/")
    }
  };

  // useEffect(() => {
  //   const homeScrallToFunLocal = (num) => {
  //     let scr = document.getElementById('homeScrall');
  //     console.log(scr.scrollTop, scr.scrollHeight, scr.clientHeight, "scr");
  //     scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight - num);
  //   // router.history.push("/#about")
  //   };
  //   console.log({
  //     ...appContext,
  //     homeScrallToFun: homeScrallToFunLocal
  //   }, "call thayu");
  //   setAppContext({
  //     ...appContext,
  //     homeScrallToFun: homeScrallToFunLocal
  //   });
  // }, []);

  useEffect(() => {
    // if (tmp != 0)
    homeScrallToFunLocal(tmp)
  }, [tmp]);

  const onScroll = () => {
    // about 4094

    // console.log(scrallRef, 'scrallRef');
    // setAppContext({ ...appContext, homeScrallRef: scrallRef });
    const { scrollTop, scrollHeight, clientHeight } = scrallRef.current;
    setSHeight(scrollTop)
    setCHeight(clientHeight)
    console.log(
      scrollTop,
      scrollHeight,
      clientHeight,
      'scrollTop, scrollHeight, clientHeight'
    );
  };

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
        homeScrallToFunLocal={homeScrallToFunLocal}
        s={s}
        setS={setS}
        sHeight={sHeight}
      />
      <div className={classes.container}>
        <main
          className={classes.content}
          onScroll={onScroll}
          ref={scrallRef}
          id="homeScrall">
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* <ChatBar /> */}
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
