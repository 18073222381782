/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  colors,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer
} from '@material-ui/core';
import axios from 'utils/axios';
import MenuIcon from '@material-ui/icons/Menu';

import Avatar from '@material-ui/core/Avatar';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import navigationConfig from '../NavBar/navigationConfig';
import ProfileDropdown from 'components/ProfileDropdown';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
  },
  flexGrow: {
    flexGrow: 1
  },
  mainHeader: {
    color: 'black',
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '79%',
    padding: 'px 0',
    paddingLeft: '35px',
    position: 'relative',
    left: '18%',
    // marginTop: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      left: '0',
      top: '0'
    }
  },
  userName: {
    marginRight: '8px',
    fontWeight: 500
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  avtar: {
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    marginRight: '20px'
  },
  textItem: {
    '& .MuiTypography-body1': {
      fontSize: '12px'
    }
  },
  // mainList: {},
  navContainer: {
    // backgroundColor: '#474646',
    // color: 'white'
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const [userName, setUserName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isDrawer, setIsDrawer] = useState(false);
  const [open, setOpen] = useState(false);

  const matches = useMediaQuery('(max-width:1280px)');

  const handleProfile = () => {
    setOpen(true);
  };

  const handleProfileClose = () => {
    setOpen(false);
  };

  // const { history } = useRouter();
  // const searchRef = useRef(null);
  // const dispatch = useDispatch();
  // const notificationsRef = useRef(null);
  // const [pricingModalOpen, setPricingModalOpen] = useState(false);
  // const [openSearchPopover, setOpenSearchPopover] = useState(false);
  // const [searchValue, setSearchValue] = useState('');
  // const [notifications, setNotifications] = useState([]);
  // const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchNotifications = async () => {
      // let name = await AsyncLocalStorage.getItem('userName');
      let url = await AsyncLocalStorage.getItem('userProfile');
      console.log(url, 'url');
      // setUserName(name);
      setImageUrl(url);
    };

    fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);
  console.log(navigationConfig[0].pages, 'pagesdnjund');
  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={() => setIsDrawer(false)}
      onKeyDown={() => setIsDrawer(false)}>
      <List>
        {navigationConfig[0].pages.map((obj, index) => {
          if (obj.children) {
            return (
              <>
                <ListItem className={classes.mainList} button key={index}>
                  <ListItemIcon className={classes.listIcon}>
                    <obj.icon />
                  </ListItemIcon>
                  {/* <Link to={obj.href}> */}
                  <ListItemText
                    className={classes.textItem}
                    primary={obj.title}
                  />
                  {/* </Link> */}
                  {/* <Drawer
                    anchor={'top'}
                    open={true}
                    // onClose={toggleDrawer(anchor, false)}
                  >
                    {obj.children &&
                      obj.children.length &&
                      obj.children.map((objChil, index) => {
                        // console.log(obj.children, 'obj.children');
                        return (
                          <ListItem
                            className={classes.mainList}
                            button
                            key={index}>
                            <Link to={objChil.href}>
                              <ListItemText
                                className={classes.textItem}
                                primary={objChil.title}
                              />
                            </Link>
                          </ListItem>
                        );
                      })}
                  </Drawer> */}
                </ListItem>
              </>
            );
          } else {
            return (
              <>
                <ListItem className={classes.mainList} button key={index}>
                  <ListItemIcon className={classes.listIcon}>
                    <obj.icon />
                  </ListItemIcon>
                  <Link to={obj.href}>
                    <ListItemText
                      className={classes.textItem}
                      primary={obj.title}
                    />
                  </Link>
                </ListItem>
              </>
            );
          }
        })}
      </List>
    </div>
  );

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
    >
      {matches ? (
        <div className={classes.mainHeader}>
          <MenuIcon onClick={() => setIsDrawer(true)} />
          <Drawer
            className={classes.navContainer}
            anchor={'left'}
            open={isDrawer}
            onClose={() => setIsDrawer(false)}>
            {list('left')}
          </Drawer>
        </div>
      ) : (
        <Toolbar>
          <RouterLink to="/admin/manageUser">
            <img alt="Logo" src={'/images/logos/sayMoreLogo.png'} />
            {/* <img alt="Logo" src={'/images/logos/Logo.svg'} /> */}
          </RouterLink>
          <div className={classes.flexGrow} />
          <Typography component="h6" variant="h6" className={classes.userName}>
            {userName}
          </Typography>

          <Avatar
            onClick={handleProfile}
            alt={userName}
            src={imageUrl || ''}
            className={classes.avtar}
          />
        </Toolbar>
      )}
      {/* <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      /> */}
      {/* <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      /> */}
      <ProfileDropdown open={open} onClose={handleProfileClose} />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
