import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import {
  AccountBalanceWalletOutlined,
  SettingsOutlined
} from '@material-ui/icons';
export default [
  {
    title: 'MANAGEMENT',
    pages: [
      // -------------------------------------- Organization --------------------------------------
      {
        title: 'Manage Users',
        href: '/admin/manageUser',
        icon: PeopleIcon
      },
      {
        title: 'Mutual Funds',
        href: '/mutualfunds',
        icon: MoneyOutlinedIcon,
        children: [
          {
            title: 'Scripts',
            href: '/admin/mutualfunds/scripts'
          },
          {
            title: 'Categories',
            href: '/admin/mutualfunds/categories'
          },
          {
            title: 'Sectors',
            href: '/admin/mutualfunds/sectors'
          }
        ]
      },
      {
        title: 'Withdraw Request',
        href: '/admin/withdrawalrequest',
        icon: AccountBalanceWalletOutlined
      },
      {
        title: 'Manage',
        href: '/manage',
        icon: SettingsOutlined,
        children: [
          {
            title: 'Mobile Notification',
            href: '/admin/manage/mobileNotification'
          },
          {
            title: 'Send Push Notification',
            href: '/admin/manage/pushNotification'
          },
          {
            title: 'Our Partners',
            href: '/admin/manage/ourPartners'
          },
          {
            title: 'Mobile Banners',
            href: '/admin/manage/mobileBanner'
          }
        ]
      }
    ]
  }
];
