import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';
import { Search } from './components';
import AddCategoryModal from '../../components/AddCategoryModal';
import AddSectorModal from 'components/AddSectorModal';
import MobileNotificationModal from 'components/MobileNotificationModal';
import PushNotificationModal from 'components/PushNotificationModal';
import AddKittySectorModal from 'components/AddKittySectorModal';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '5px',
    padding: '25px 25px 8px 25px',
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  search: {
    flexGrow: 1,
    // [theme.breakpoints.up('lg')]: {
    //   width: "600px",
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: "450px",
    // },
    width: '100%',
    flexBasis: 480
  },
  addButton: {
    paddingRight: '15px',
    padding: '8px',
    height: '35px',
    minWidth: '84px',
    color: '#474646',
    backgroundImage:
      'linear-gradient(261.94deg, #FFD24C 0%, #FFD24C 46.4%, #FFEF33 100%)'
  },
  filterIcon: {
    marginRight: theme.spacing(1)
    //
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  }
}));

const SearchBar = props => {
  const {
    addUrl,
    pushNotification,
    mobileNotification,
    addSectorModal,
    addKittySectorModal,
    add,
    onload,
    onFilter,
    addCategoryModal,
    setSessionMessage,
    addModel,
    addModel2,
    onSearch,
    setOpen,
    setOpenBanner2,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const handleAddCategory = () => {
    setOpen1(true);
  };

  const handleAddSector = () => {
    setOpen2(true);
  };

  const handleAddKittySector = () => {
    setOpen5(true);
  };

  const handleMobileNotification = () => {
    setOpen3(true);
  };

  const handlePushNotification = () => {
    setOpen4(true);
  };

  const handleCloseCat = () => {
    setOpen1(false);
  };

  const handleCloseSec = () => {
    setOpen2(false);
  };
  const handleCloseKittySec = () => {
    setOpen5(false);
  };

  const handleCloseMobileNotification = () => {
    setOpen3(false);
  };

  const handleClosePushNotification = () => {
    setOpen4(false);
  };

  const addPartnerModel = () => {
    setOpen(true);
  };

  const addPartnerModel2 = () => {
    setOpenBanner2(true);
  };

  // const [openFilter, setOpenFilter] = useState(false);

  // const handleFilterOpen = () => {
  //   setOpenFilter(true);
  // };

  // const handleFilterClose = () => {
  //   setOpenFilter(false);
  // };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      <Grid item xs={8} sm={8} md={6} lg={5} xl={5}>
        <Search className={classes.search} onSearch={onSearch} />
      </Grid>
      {add && (
        <Grid
          className={classes.addContainer}
          item
          xs={4}
          sm={4}
          md={6}
          lg={7}
          xl={7}
          // style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {addCategoryModal && (
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={handleAddCategory}>
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
          {addSectorModal && (
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={handleAddSector}>
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
          {addKittySectorModal && (
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={handleAddKittySector}>
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
          {mobileNotification && (
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={handleMobileNotification}>
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
          {pushNotification && (
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={handlePushNotification}>
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
          {addUrl && (
            <Button
              className={classes.addButton}
              color="primary"
              to={addUrl}
              component={RouterLink}
              size="small"
              variant="contained">
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
          {addModel && (
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              onClick={addPartnerModel}
              variant="contained">
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
          {addModel2 && (
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              onClick={addPartnerModel2}
              variant="contained">
              <AddIcon className={classes.filterIcon} />
              Add
            </Button>
          )}
        </Grid>
      )}
      {/* <Filter
        onClose={handleFilterClose}
        onFilter={onFilter}
        open={openFilter}
      /> */}
      {open1 && (
        <AddCategoryModal
          onload={onload}
          setSessionMessage={setSessionMessage}
          open={open1}
          onClose={handleCloseCat}
        />
      )}
      {open2 && (
        <AddSectorModal
          open={open2}
          onload={onload}
          setSessionMessage={setSessionMessage}
          onClose={handleCloseSec}
        />
      )}
      {open3 && (
        <MobileNotificationModal
          open={open3}
          onload={onload}
          setSessionMessage={setSessionMessage}
          onClose={handleCloseMobileNotification}
        />
      )}
      {open4 && (
        <PushNotificationModal
          open={open4}
          onload={onload}
          setSessionMessage={setSessionMessage}
          onClose={handleClosePushNotification}
        />
      )}
      {open5 && (
        <AddKittySectorModal
          open={open5}
          onload={onload}
          setSessionMessage={setSessionMessage}
          onClose={handleCloseKittySec}
        />
      )}
    </Grid>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchBar;
