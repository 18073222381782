import React, { useEffect } from 'react';
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../Helper/ApiHandler';
import { validate } from 'validate.js';
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  Categoryname: {
    presence: { allowEmpty: false, message: 'is required' }
  }
  // ScriptType: {
  //   presence: { allowEmpty: false, message: 'is required' }
  // }
};

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 10px 10px 10px',
    '& .MuiButton-root': {
      // textTransform: 'capitalize'
    }
  },
  divider: {
    '& .MuiDivider-root': {
      backgroundColor: '#0000002b'
    }
  },
  dialogRoot: {
    padding: '10px',
    '& .MuiDialog-paper': {
      // overflow: "visible"
      // height: '260px',
      borderRadius: '10px'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(0 0 0 / 55%)'
    },
    '& .MuiPaper-elevation24': {
      // boxShadow: 'none'
    }
  },
  btnSave: {
    borderRadius: '5px',
    fontSize: '12px',
    backgroundImage:
      'linear-gradient(261.94deg, #FFD24C 0%, #FFD24C 46.4%, #FFEF33 100%)',
    color: 'white',
    textTransform: 'uppercase',
    width: '120px'
  },
  btnCancel: {
    borderRadius: '5px',
    fontSize: '12px',
    width: '120px'
  },
  flexWithPading: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      fontSize: '14px'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
      fontSize: '16px'
    }
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  }
}));
function AddCategoryModal(props) {
  const classes = useStyles();
  const {
    setSessionMessage,
    userType,
    componentName,
    slug,
    onload,
    onClose,
    open
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [scripts, setScripts] = React.useState([]);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  // const [sessionMessage, setSessionMessage] = React.useState({
  //   message: '',
  //   type: '',
  //   open: false
  // });

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    console.log(formState.values, 'formState.values');
    // console.log(formState.errors, 'formState.errors');
  }, [formState.values]);

  useEffect(() => {
    getScriptList();
  }, []);

  const getScriptList = () => {
    api
      .get(`getinvestmentcategory`)
      .then(result => {
        if (result.success === 'true') {
          setScripts(result.data);
        } else {
          console.log(result, 'error');
        }
      })
      .catch(err => {
        console.log(err, 'catch error');
      });
  };

  const handleFieldChange = event => {
    event.persist();
    console.log(event.target.value, 'event.target.value');
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSubmit = async event => {
    event.preventDefault();

    if (formState.isValid === true) {
      setDisabled(true);
      setLoading(true);
      api
        .post(`admin/addscriptcategory`, {
          data: {
            investment_category_id: 1,
            name: formState.values.Categoryname
          }
        })
        .then(result => {
          if (result.success === 'true') {
            setSessionMessage({
              message: result.message,
              type: 'success',
              open: true
            });
            onClose();
            onload();
            console.log(result, 'result');
          } else {
            console.log(result, 'result');
            setSessionMessage({
              message: result.message,
              type: 'error',
              open: true
            });
          }
          setDisabled(false);
          setLoading(false);
        })
        .catch(err => {
          console.log(err, 'error catch');
          setDisabled(false);
          setLoading(false);
          setSessionMessage({
            message: err.message,
            type: 'error',
            open: true
          });
        });
    }
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  };

  return (
    <>
      {/* {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}>
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )} */}
      <Dialog
        className={classes.dialogRoot}
        onClose={handleClose}
        // maxWidth="xs"
        open={open}>
        <Grid container justify="center" style={{ padding: '20px' }}>
          <Grid item xs={12} className={classes.flex}>
            <Typography>Add Category</Typography>
          </Grid>
        </Grid>
        <div className={classes.divider}>
          <Divider />
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container justify="center" style={{ padding: '20px' }}>
            <Grid item xs={12} className={classes.flexWithPading}>
              {/* <FormControl
                fullWidth
                size="small"
                variant="outlined"
                // style={{ fontSize: '12px' }}
                className={classes.formControlGender}>
                <InputLabel
                  // style={{ width: '150px' }}
                  id="demo-simple-select-outlined-label">
                  Script Type <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Select
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk
                    }
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    }
                  }}
                  error={hasError('ScriptType')}
                  helperText={
                    hasError('ScriptType')
                      ? formState.errors.ScriptType[0]
                      : null
                  }
                  name="ScriptType"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={formState.values.ScriptType || ''}
                  onChange={handleFieldChange}
                  label="Script Type">
                  {scripts.map(script => {
                    return <MenuItem value={script.id}>{script.name}</MenuItem>;
                  })}
                </Select>
              </FormControl> */}
              <TextField
                required
                size="small"
                disabled
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk
                  }
                }}
                fullWidth
                error={hasError('ScriptType')}
                type="text"
                helperText={
                  hasError('ScriptType') ? formState.errors.ScriptType[0] : null
                }
                label="Script Type"
                name="ScriptType"
                value="Mutual Funds"
                variant="outlined"
                // size="small"
              />
            </Grid>
            <Grid item xs={12} className={classes.flexWithPading}>
              <TextField
                required
                size="small"
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk
                  }
                }}
                fullWidth
                error={hasError('Categoryname')}
                type="text"
                helperText={
                  hasError('Categoryname')
                    ? formState.errors.Categoryname[0]
                    : null
                }
                label="Category name"
                name="Categoryname"
                onChange={handleFieldChange}
                value={formState.values.Categoryname || ''}
                variant="outlined"
                // size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.dialogButtonHeader}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                  }}
                  className={classes.btnCancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={formState.isValid ? disabled : true}
                  className={classes.btnSave}>
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  );
}

export default AddCategoryModal;
