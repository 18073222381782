/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import PolicyDashboard from 'layouts/PolicyDashboard';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import OrganizationDashboardLayout from './layouts/OrganizationDashboard';
import RequireAuthOrg from './RequireAuthOrg';
const routes = [
  // {
  //   path: '/',
  //   exact: true,
  //   component: () => <Redirect to="/" />
  // },
  {
    path: '/admin',
    exact: true,
    component: () => <Redirect to="/admin/login" />
  },

  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  // {
  //   path: '/',
  //   component: PolicyDashboard,
  //   routes: [
  //     {
  //       path: '/home',
  //       exact: true,
  //       component: lazy(() => import('views/Home'))
  //     }
  //   ]
  // },
  {
    path: '/page',
    component: PolicyDashboard,
    routes: [
      {
        path: '/page/privacy-policy',
        exact: true,
        component: lazy(() => import('views/PrivacyPolicy'))
      },
      {
        path: '/page/disclaimer',
        exact: true,
        component: lazy(() => import('views/Disclaimer'))
      },
      {
        path: '/page/what-we-are',
        exact: true,
        component: lazy(() => import('views/WhatWeAre'))
      },
      {
        path: '/page/terms-and-conditions',
        exact: true,
        component: lazy(() => import('views/T&C'))
      }
    ]
  },
  {
    path: '/admin',
    component: AuthLayout,
    routes: [
      {
        path: '/admin/login',
        exact: true,
        component: lazy(() => import('views/LoginOrganization'))
      },
      {
        path: '/',
        component: OrganizationDashboardLayout,
        routes: [
          // *************************  Saymore  ************************
          {
            path: '/admin/change-password',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/Changepassword'))
            )
          },

          //manageUser***********************************
          {
            path: '/admin/manageUser',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageUser')))
          },
          {
            path: '/admin/manageUser/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageUser')))
          },
          {
            path: '/admin/manageUser/user/add',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddUser')))
          },
          {
            path: '/admin/manageUser/edit/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditUser')))
          },
          {
            path: '/admin/manageUser/UserDetails/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/manageUserDetails'))
            )
          },
          //manageLeaders***********************************
          {
            path: '/admin/manageLeaders',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageLeaders')))
          },
          {
            path: '/admin/manageLeaders/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageLeaders')))
          },
          {
            path: '/admin/manageLeaders/leaders/add',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddLeaders')))
          },
          {
            path: '/admin/manageLeaders/edit/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditLeaders')))
          },
          {
            path: '/admin/manageLeaders/leaderDetails/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/manageLeadersDetails'))
            )
          },
          //manageEmployees***********************************
          {
            path: '/admin/manageEmployees',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageEmployees')))
          },
          {
            path: '/admin/manageEmployees/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageEmployees')))
          },
          {
            path: '/admin/manageEmployees/employees/add',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddEmployee')))
          },
          {
            path: '/admin/manageEmployees/edit/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditEmployee')))
          },
          {
            path: '/admin/manageEmployees/employeeDetails/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/manageEmployeeDetails'))
            )
          },

          //manageAdvisors***********************************
          {
            path: '/admin/manageAdvisors',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageAdvisors')))
          },
          {
            path: '/admin/manageAdvisors/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SayManageAdvisors')))
          },
          {
            path: '/admin/manageAdvisors/advisor/add',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddAdvisors')))
          },
          {
            path: '/admin/manageAdvisors/edit/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditAdvisors')))
          },
          {
            path: '/admin/manageAdvisors/AdvisorsDetails/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/manageAdvisorsDetails'))
            )
          },
          {
            path: '/admin/transactions',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Transactions')))
          },
          {
            path: '/admin/personal-details/:slug',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/UserPersonalDetails'))
            )
          },

          {
            path: '/admin/DashboardAnalytics',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/DashboardAnalytics')))
          },
          // {
          //   path: '/DashboardDefault',
          //   exact: true,
          //   component: RequireAuthOrg(lazy(() => import('views/DashboardDefault')))
          // },

          //mutualFunds***********************************
          // {
          //   path: '/mutualfunds',
          //   exact: true,
          //   component: RequireAuthOrg(lazy(() => import('views/PaymentLogs')))
          // },
          {
            path: '/admin/mutualfunds/orders',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Orders')))
          },
          {
            path: '/admin/fixeddeposit/fdorders',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/FdOrders')))
          },
          {
            path: '/admin/kitty/orders',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/KittyOrders')))
          },
          {
            path: '/admin/mutualfunds/scripts',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Scripts')))
          },
          {
            path: '/admin/fixeddeposit/scripts',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/FdScripts')))
          },
          {
            path: '/admin/kitty/lumsumscheme',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/LumSum')))
          },
          {
            path: '/admin/kitty/lumsumscheme/lumsumschemeDetails/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/LumsumschemeDetails')))
          },
          {
            path: '/admin/kitty/scheme',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Scheme')))
          },
          {
            path: '/admin/mutualfunds/scripts/scriptDetails/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/ScriptDetails')))
          },
          {
            path: '/admin/fixeddeposit/scripts/scriptDetails/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/FdScriptDetails')))
          },
          {
            path: '/admin/kitty/scheme/schemeDetails/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SchemeDetails')))
          },
          {
            path: '/admin/mutualfunds/scripts/addscript',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddScript')))
          },
          {
            path: '/admin/fixeddeposit/scripts/addscript',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddFdScript')))
          },
          {
            path: '/admin/kitty/lumsumscheme/addlumpcumscheme',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddScript copy')))
          },
          {
            path: '/admin/kitty/scheme/addscheme',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddScheme')))
          },
          {
            path: '/admin/kitty/scheme/editscheme/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditScheme')))
          },
          {
            path: '/admin/mutualfunds/scripts/editscript/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditScript')))
          },
          {
            path: '/admin/fixeddeposit/scripts/editscript/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditFdScript')))
          },
          {
            path: '/admin/kitty/lumsumscheme/lumsumschemeEdit/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditLupmsumScheme')))
          },
          {
            path: '/admin/kitty/lumsumscheme/fundholding/edit/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/LumpsumEditFundHolding'))
            )
          },
          {
            path: '/admin/mutualfunds/scripts/fundholding/edit/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/EditFundHolding'))
            )
          },
          {
            path: '/admin/kitty/scheme/fundholding/edit/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/EditSchemeFundHolding'))
            )
          },
          {
            path: '/admin/mutualfunds/scripts/addNav/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddNAV')))
          },

          {
            path: '/admin/mutualfunds/categories',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Categories')))
          },
          {
            path: '/admin/kitty/withdrawalrequest',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/KittyWithdrawalRequest')))
          },
          {
            path: '/admin/kitty/managewealthaccount',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/ManageWealthAccount')))
          },
          {
            path: '/admin/mutualfunds/sectors',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Sectors')))
          },
          {
            path: '/admin/kitty/sectors',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/KittySectors')))
          },

          // withdrawal***********************************
          {
            path: '/admin/walletwithdrawrequest',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/WithdrawalRequest'))
            )
          },

          //manage****************************************
          {
            path: '/admin/manage/mobileNotification',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/MobileNotification'))
            )
          },
          {
            path: '/admin/manage/pushNotification',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/PushNotification'))
            )
          },
          {
            path: '/admin/manage/ourPartners',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/OurPartners'))
            )
          },
          {
            path: '/admin/manage/Commission',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/Commission'))
            )
          }, 
          {
            path: '/admin/manage/mobileBanner',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/MobileBanner'))
            )
          },
          // *************************  NOC From indian gov  ************************
          {
            path: '/admin/noc',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Testimonials copy')))
          },
          // *************************  Testimonials  ************************
          {
            path: '/admin/manage/testimonials',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Testimonials')))
          },
          {
            path: '/admin/manage/testimonials/add',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddTestimonials')))
          },
          {
            path: '/admin/manage/testimonials/editTestimonials/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditTestimonials')))
          },
          {
            path: '/admin/manage/contactUs',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Contactus')))
          },
          // *************************************************************
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: PolicyDashboard,
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('views/Home'))
      },
      {
        path: '/contact-us',
        exact: true,
        component: lazy(() => import('views/Home/components/Home/ContactUs'))
      }
      // {
      //   path: '/page/privacy-policy',
      //   exact: true,
      //   component: lazy(() => import('views/PrivacyPolicy'))
      // },
      // {
      //   path: '/page/disclaimer',
      //   exact: true,
      //   component: lazy(() => import('views/Disclaimer'))
      // },
      // {
      //   path: '/page/what-we-are',
      //   exact: true,
      //   component: lazy(() => import('views/WhatWeAre'))
      // },
      // {
      //   path: '/page/terms-and-conditions',
      //   exact: true,
      //   component: lazy(() => import('views/T&C'))
      // }
    ]
  }
];

export default routes;
