import React, { useEffect } from 'react';
import { Divider, Grid, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Api from '../../Helper/ApiHandler';
import { validate } from 'validate.js';
var api = new Api();

const schema = {
  Title: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  Description: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 10px 10px 10px',
    '& .MuiButton-root': {
      // textTransform: 'capitalize'
    }
  },
  divider: {
    '& .MuiDivider-root': {
      backgroundColor: '#0000002b'
    }
  },
  dialogRoot: {
    padding: '10px',
    '& .MuiDialog-paper': {
      // overflow: "visible"
      // height: '370px',
      width: '500px',
      borderRadius: '10px'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(0 0 0 / 55%)'
    },
    '& .MuiPaper-elevation24': {
      // boxShadow: 'none'
    }
  },
  btnSave: {
    borderRadius: '5px',
    fontSize: '12px',
    backgroundImage:
      'linear-gradient(261.94deg, #FFD24C 0%, #FFD24C 46.4%, #FFEF33 100%)',
    color: 'white',
    textTransform: 'uppercase',
    width: '120px'
  },
  btnCancel: {
    borderRadius: '5px',
    fontSize: '12px',
    width: '120px'
  },
  flexWithPading: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      fontSize: '14px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '6px'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
      fontSize: '16px'
    }
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  }
}));
function MobileNotificationModal(props) {
  const classes = useStyles();
  const {
    userType,
    componentName,
    setSessionMessage,
    slug,
    onload,
    onClose,
    open
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const onCloseLocal = event => {
    onClose();
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // console.log(formState.values, 'formState.values');
    console.log(formState.errors, 'formState.errors');
  }, [formState.values]);

  const handleFieldChange = event => {
    event.persist();
    console.log(event.target.value, 'event.target.value');
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSubmit = async event => {
    event.preventDefault();

    if (formState.isValid === true) {
      setDisabled(true);
      setLoading(true);
      api
        .post('admin/addmobilenotify', {
          data: {
            title: formState.values.Title,
            description: formState.values.Description
          }
        })
        .then(result => {
          if (result.success === 'true') {
            setSessionMessage({
              message: result.message,
              type: 'success',
              open: true
            });
            onload();
            onClose();
            console.log(result, 'result');
          } else {
            console.log(result, 'result');
            setSessionMessage({
              message: result.message,
              type: 'error',
              open: true
            });
          }
          setDisabled(false);
          setLoading(false);
        })
        .catch(err => {
          console.log(err, 'error catch');
          setDisabled(false);
          setLoading(false);
          setSessionMessage({
            message: err.message,
            type: 'error',
            open: true
          });
        });
    }
  };

  return (
    <Dialog
      className={classes.dialogRoot}
      onClose={onCloseLocal}
      // maxWidth="xs"
      open={open}>
      <Grid container justify="center" style={{ padding: '25px' }}>
        <Grid item xs={12} className={classes.flex}>
          <Typography>Mobile Notification</Typography>
        </Grid>
      </Grid>
      <div className={classes.divider}>
        <Divider />
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" style={{ padding: '20px' }}>
          <Grid item xs={12} className={classes.flexWithPading}>
            <TextField
              required
              size="small"
              InputLabelProps={{
                classes: {
                  asterisk: classes.asterisk
                }
              }}
              fullWidth
              error={hasError('Title')}
              type="text"
              helperText={hasError('Title') ? formState.errors.Title[0] : null}
              label="Title"
              name="Title"
              onChange={handleFieldChange}
              value={formState.values.Title || ''}
              variant="outlined"
              // size="small"
            />
          </Grid>
          <Grid item xs={12} className={classes.flexWithPading}>
            <TextField
              multiline={true}
              rows={5}
              required
              size="small"
              InputLabelProps={{
                classes: {
                  asterisk: classes.asterisk
                }
              }}
              fullWidth
              error={hasError('Description')}
              type="text"
              helperText={
                hasError('Description') ? formState.errors.Description[0] : null
              }
              label="Description"
              name="Description"
              onChange={handleFieldChange}
              value={formState.values.Description || ''}
              variant="outlined"
              // size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dialogButtonHeader}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  onCloseLocal();
                }}
                className={classes.btnCancel}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                color="primary"
                variant="contained"
                className={classes.btnSave}
                type="submit"
                disabled={formState.isValid ? disabled : true}>
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}

export default MobileNotificationModal;
