export default {
  root: {
    // backgroundColor: colors.grey[50],
    backgroundColor: "#e6e6e6",
    color: "black",
    '& .MuiTableCell-head': {
      fontSize: "12px",
      fontWeight: 700
    }
  }
};
